@font-face {
    font-family: 'Goldplay';
    src: url('Goldplay-Medium.eot');
    src: local('Goldplay Medium'), local('Goldplay-Medium'),
        url('Goldplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('Goldplay-Medium.woff2') format('woff2'),
        url('Goldplay-Medium.woff') format('woff'),
        url('Goldplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Goldplay';
    src: url('Goldplay-SemiBold.eot');
    src: local('Goldplay SemiBold'), local('Goldplay-SemiBold'),
        url('Goldplay-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Goldplay-SemiBold.woff2') format('woff2'),
        url('Goldplay-SemiBold.woff') format('woff'),
        url('Goldplay-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
